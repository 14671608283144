<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Nota
                        </th>
                        <th class="text-left">
                            Data
                        </th>
                        <th class="text-left">
                            Validado
                        </th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr @click="invoiceValidate(invoice.invoiceId)" v-bind:class="{notValidated : (invoice.validated ?  false: true)}" v-for="(invoice) in invoices" :key="invoice.invoiceId">
                        <td>{{ invoice.invoiceId }}</td>
                        <td>{{ new Date(invoice.date).toLocaleString() }}</td>
                        <td>{{ invoice.validated ? new Date(invoice.validated).toLocaleString() : "" }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <a v-if="qty < 999" @click="fetchMore">Ver mais...</a>
    </div>
</template>

<script>
import api from "./api.js";

export default {
    data: function() {
        return {
            invoices: [],
            qty: 6,
        };
    },
    created: async function() {
        this.invoices = await api.fetchInvoices(this.qty);
    },
    methods: {
        invoiceValidate: function(invoice) {
            window.location = ('/validar/' + invoice)
        },
        fetchMore: async function() {
            this.qty = 1000;
            this.invoices = await api.fetchInvoices(this.qty);
        }
    }
};
</script>

<style>
.notValidated {
    color: rgb(145, 84, 5);
    font-weight: 600;
}
</style>
