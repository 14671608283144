import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Login,
    },
    {
        path: "/validar",
        name: "Validar",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Validate.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/validar/:id",
        name: "Validar",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Validate.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/report",
        name: "Relatório",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Report.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/schedule",
        name: "Programação",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Schedule.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/deliveries",
        name: "Entregas",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Deliveries.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/programacao",
        name: "Programação",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/ScheduleClient.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login-confirmation",
        name: "Confirmação de Login",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/LoginConfirmation.vue"
            ),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

export default router;
