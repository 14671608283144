import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        invoiceId: null,
        products: [],
        token: localStorage.getItem("token") || "",
        user: localStorage.getItem("user") || {},
        elements: [],
    },
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, user) {
            let { email } = user;
            state.status = "success";
            state.user = { email };
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            firebase
                .auth()
                .signOut()
                .then(function() {
                    // Sign-out successful.
                    console.log("Logged Out");
                })
                .catch(function(error) {
                    // An error happened.
                    console.log(error);
                });
            state.token = "";
            state.user = "";
            window.location = "/login";
        },
        updateSchedule: (state, payload) => {
            state.elements = payload;
        },
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");

                let host = window.location.origin;

                var actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be in the authorized domains list in the Firebase Console.
                    url: host + "/login-confirmation",
                    // This must be true.
                    handleCodeInApp: true,
                };

                let email = user.email;

                firebase
                    .auth()
                    .sendSignInLinkToEmail(email, actionCodeSettings)
                    .then(function() {
                        // The link was successfully sent. Inform the user.
                        // Save the email locally so you don't need to ask the user for it again
                        // if they open the link on the same device.
                        window.localStorage.setItem(
                            "emailForSignIn",
                            email.toLowerCase()
                        );
                        resolve();
                    })
                    .catch(function() {
                        // Some error occurred, you can inspect the code: error.code
                        commit("auth_error");
                        localStorage.removeItem("emailForSignIn");
                        reject();
                    });
            });
        },
        updateSchedule: ({ commit }, payload) => {
            commit("updateSchedule", payload);
        },
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
    },
    modules: {},
});
