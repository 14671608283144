<template>
    <div>
        <form class="login">
            <h1>Login</h1>
            <div>
                <v-text-field
                    label="Digite seu email"
                    :rules="rules"
                    hide-details="auto"
                    v-model="email"
                ></v-text-field>
            </div>
            <div class="warning-msg">
                <p>{{ message }}</p>
            </div>
            <div>
                <v-btn @click="login">Login</v-btn>
            </div>
        </form>
    </div>
</template>

<script>
import userGuard from '../helpers/user-guard.js'
export default {
    data() {
        return {
            email: "",
            password: "",
            message: "",
            rules: [
                (value) => !!value || "Obrigatório",
                (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Email inválido";
                },
            ],
        };
    },
    methods: {
        login: function() {
            let email = this.email;
            console.log(email)
            let t = this;
            if (userGuard(email)) {
                this.$store
                    .dispatch("login", { email })
                    .then(function() {
                        t.message =
                            "Um email foi enviado com o link para Login";
                    })
                    .catch((err) => console.log(err));
            } else {
                this.message =
                    "Apenas emails @galvanorte.com.br e @ariamequipamentos.com.br são permitidos";
            }
        },
    },
};
</script>
