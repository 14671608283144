<script>
export default {
  props: ["product"],
};
</script>
<template>
  <div class="product-card-horizontal">
    <img v-bind:src="product.image" />
    <div class="product-card-horizontal-infos">
      <div>{{ product.cod }}</div>

      <div>{{ product.name }}</div>
      <div v-if="product.qty">{{ product.qty }} peças</div>
      <slot></slot>
    </div>
  </div>
</template>
<style>
.product-card-horizontal {
  font-size: 12px;
  display: flex;
  margin-bottom: 5px;
}

.product-card-horizontal img {
  height: 80px;
  margin-right: 5px;
}

.product-card-horizontal-infos {
  display: block;
}
</style>
