<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import server from "../firebase/firebase.js";

export default {
    components: { VueCropper },
    props: ["image_name", "product"],
    data: function() {
        return {
            mime_type: "",
            cropedImage: "",
            autoCrop: false,
            selectedFile: "",
            image: "",
            imageUrl: "",
            dialog: false,
            files: "",
            savingImage: false,
            canvas: null,
            productData: {},
            productNickname: "",
            productBillingCod: "",
            productWeight: "",
            imageChanged: false,
        };
    },
    mounted() {
        this.canvas = document.getElementById("img-canvas");
        this.fetchProductData();
    },
    watch: { 
        product: function () {
            if(this.productData && (this.productData.cod !== this.product.cod)) {
                let ctx = this.canvas.getContext("2d");
                ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.fetchProductData();
            }
        } 
    },
    methods: {
        async fetchProductData() {
            this.productData = await server.getProduct(this.product.cod);
            var ctx = this.canvas.getContext("2d");
            var img = new Image();
            img.src = this.productData.image;
            img.crossOrigin = "Anonymous";
            img.onload = function() {
                ctx.drawImage(img, 0, 0, 300, 300); // Or at whatever offset you like
            };
            this.productNickname = this.productData.nickname;
            this.productBillingCod = this.productData.billingCod;
            this.productWeight = this.productData.weight;
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        async saveProduct () {
            if (this.imageChanged) {
                await this.updloadImage()
            }
            let product = {
                name: this.product.name,
                cod: this.product.cod,
                image: (this.imageChanged ? this.imageUrl : this.product.image),
                billingCod: (this.productBillingCod ? this.productBillingCod : ""),
                nickname: (this.productNickname ? this.productNickname : ""),
                weight: (this.productWeight ? this.productWeight : 0)
            }
            await server.saveProduct(product);
            this.$emit('saveDone');
        },
        async updloadImage() {
            this.savingImage = true;
            const productId = this.product.cod;
            this.imageUrl = await server.saveImage(
                productId,
                this.dataURLtoFile(this.canvas.toDataURL("image/jpg"))
            );
            let t = this;
            let productGridIndex = this.$store.state.products.findIndex(function(product) {
                        return product.cod == t.product.cod;
            })
            if(productGridIndex  > 0) {
                this.$store.state.products[productGridIndex].image = this.imageUrl;
            }
            
            this.savingImage = false;

        },
        saveImage() {
            this.cropedImage = this.$refs.cropper
                .getCroppedCanvas()
                .toDataURL();

            let ctx = this.canvas.getContext("2d");
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            ctx.drawImage(
                this.$refs.cropper.getCroppedCanvas(),
                0,
                0,
                300,
                300
            );
        },
        onFileSelect(e) {
            const file = e.target.files[0];
            this.mime_type = file.type;
            if (typeof FileReader === "function") {
                this.dialog = true;
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.selectedFile = event.target.result;
                    this.$refs.cropper.replace(this.selectedFile);
                };
                reader.readAsDataURL(file);
                this.imageChanged = true;
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
    },
};
</script>

<template>
    <div class="white lighten-2">
        <div size="120" class="product">
            <canvas id="img-canvas" width="300" height="300"> </canvas>
            <div v-show="savingImage" class="loading-icon">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <input
                ref="FileInput"
                type="file"
                style="display: none;"
                @change="onFileSelect"
            />
        </div>
        <v-btn
                v-show="!savingImage"
                class="icon primary"
                @click="$refs.FileInput.click()"
                >Trocar</v-btn>
        <h4>{{ product.name }}</h4>
        <div>
            <v-label>Codigo: </v-label>
            <span> {{ product.cod }}</span>
        </div>
        <div>
            <v-label>Faturamento: </v-label>
            <input v-model="productBillingCod"/>
        </div>
        <div>
            <v-label>Peso: </v-label>
            <input type="number" v-model="productWeight"/>
        </div>
        <div>
            <v-label>Apelido: </v-label>
            <input v-model="productNickname"/>
        </div>
        <v-btn text color="primary" @click="saveProduct">Salvar Alterações</v-btn>

        <v-dialog v-model="dialog">
            <v-card>
                <v-card-text>
                    <VueCropper
                        class="image-cropper"
                        v-show="selectedFile"
                        ref="cropper"
                        :src="selectedFile"
                        :aspectRatio="1 / 1"
                        :initialAspectRatio="1 / 1"
                        alt="Source Image"
                    ></VueCropper>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="primary"
                        @click="saveImage();(dialog = false)"
                        >Crop</v-btn
                    >
                    <v-btn color="primary" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<style scoped>
#img-canvas {
    width: 100%;
}


.loading-icon {
    position: absolute;
    top: 80px;
    left: 80px;
}
.product {
    width: 100%;
    border: 3px solid #adadad;
    position: relative;
}

.product-img {
    height: 100%;
    width: 100%;
}

.image-cropper {
    max-height: 300px;
}

.icon {
    background: #e2e2e2;
    padding: 5px;
    width: 100%;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    color: #0000ff;
    font-size: 14px;
    cursor: pointer;
}
</style>
