<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-btn text>
                        <span class="">Início</span>
                    </v-btn>
                </router-link>
            </div>

            <v-spacer></v-spacer>

            <template v-if="$store.getters.isLoggedIn">
                <v-btn icon x-large @click="userPopup = !(userPopup == true)">
                    <v-avatar color="brown" size="48">
                        <span class="white--text headline">{{
                            $store.state.user.slice(0,2)
                        }}</span>
                    </v-avatar>
                </v-btn>
            </template>

            <v-card v-show="userPopup">
                <v-list-item-content>
                    <div class="">
                        <v-btn @click="logout" depressed rounded text>
                            Logout
                        </v-btn>
                    </div>
                </v-list-item-content>
            </v-card>
        </v-app-bar>

        <v-main>
            <router-view class="container"></router-view>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    components: {},

    data: () => ({
        userPopup: false
    }),
    methods: {
        logout() {
            this.$store.commit("logout");
        }
    }
};
</script>

<style scoped>
</style>