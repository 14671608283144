export default function(e) {
    let email = e.toLowerCase();
    if (
        email.indexOf("@galvanorte.com.br") > -1 ||
        email.indexOf("@galvanorte.com.br") > -1 ||
        email.indexOf("eng.caioamaral@gmail.com") > -1
    ) {
        return true;
    } else {
        return false;
    }
}
