import server from "../firebase/firebase.js";
const api = {
    sendValidation: async function (data) {
        // Local: "http://localhost:5001/ariam-galvanorte/us-central1/validate"
        let response = await fetch(
            "https://us-central1-ariam-galvanorte.cloudfunctions.net/validate",
            {
                method: "post",
                body: JSON.stringify(data),
            }
        );
        if (response.ok) {
            // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();
            return json;
        } else {
            alert("HTTP-Error: " + response.status);
        }
    },
    validateLoad: async function (data, schedule) {
        // Local: "http://localhost:5001/ariam-galvanorte/us-central1/validateLoad"
        // Prod: "https://us-central1-ariam-galvanorte.cloudfunctions.net/validateLoad"
        let response = await fetch(
            "https://us-central1-ariam-galvanorte.cloudfunctions.net/validateLoad",
            {
                method: "post",
                body: JSON.stringify({ data, schedule }),
            }
        );
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            alert("HTTP-Error: " + response.status);
        }
    },
    listProducts: async function () {
        let products = await server.getProducts();
        return products;
    },
    fetchScheduleItems: async function () {
        let scheduleItems = await server.getProductionSchedule();
        return scheduleItems;
    },
    saveSchedule: async function (schedule) {
        let ret = await server.saveSchedule(schedule);
        return ret;
    },
    saveDoneBasket: async function (basket) {
        let ret = await server.saveDoneBasket(basket);
        return ret;
    },
    fetchInProcessParts: async function () {
        let ret = await server.getInProcessParts();
        return ret
    },
    fetchDeliveries: async function (qty) {
        let deliveries = await server.getDeliveries(qty);
        return deliveries;
    },
    saveDelivery: async function (delivery) {
        let ret = await server.createDelivery(delivery);
        return ret;
    },
    fetchProductsWithoutInvoice: async function () {
        let products = await server.getItemsWaitingInvoice();
        return products;
    },
    fetchProductsOnlyWithInvoice: async function () {
        let products = await server.getItemsWaitingParts();
        return products;
    },
    fetchPendingItems: async function () {
        let pendingInvoice = await server.getItemsWaitingInvoice();
        let pendingProduct = await server.getItemsWaitingParts();
        let pendingItems = {
            pendingInvoice,
            pendingProduct,
        };
        return pendingItems;
    },
    fetchInvoices: async function (qty) {
        let invoices = await server.getInvoices(qty);
        return invoices;
    },
    fetchInvoiceData: async function (invoiceId) {
        let invoice = {};

        let response = await fetch(
            "https://script.google.com/macros/s/AKfycbwbwq4dEeFL96oTe7LblkOguPlTWdocoW0JaTWhu6UMoC9C3_w/exec?function=openNf&parameter=" +
            invoiceId
        );

        if (response.ok) {
            // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();

            if (json.items && json.items.length > 0) {
                invoice.items = json.items;
                invoice.date = json.dataCompleta;
                return invoice;
            } else {
                return null;
            }
        } else {
            alert("HTTP-Error: " + response.status);
        }
    },
};

export default api;
