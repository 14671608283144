<template>
    <div>

        <v-card :elevation="3" class="my-2">
            <v-card-title>Buscar Produto</v-card-title>
            <SearchProduct v-on:selected="openProduct"></SearchProduct>
        </v-card>

        <v-card :elevation="3" class="my-2 pa-1">
            <v-card-title>Entregas</v-card-title>
            <a href="/deliveries">Ver entregas</a>
        </v-card>

        <!-- <v-card :elevation="3" class="my-2">
            <v-card-title>Programação</v-card-title>
            <a href="/schedule">Abrir Programação</a>
        </v-card> -->

        <v-card :elevation="3" class="my-2 pa-1">
            <v-card-title>Validar uma nota</v-card-title>
            <input type="text" v-model="nota" placeholder="Número da nota" />
            <div>
                <router-link :to="'/validar/' + nota">
                    <v-btn>Validar</v-btn>
                </router-link>
                <router-link to="/validar">
                    <v-btn>Carga sem nota</v-btn>
                </router-link>
            </div>
        </v-card>

        <v-card :elevation="3" class="my-2 pa-1">
            <v-card-title>Notas</v-card-title>
            <Invoices></Invoices>
        </v-card>

        <v-card :elevation="3" class="my-2 pa-1">
            <v-card-title>Relatório</v-card-title>
            <a href="/report">Gerar Relatório</a>
        </v-card>

        <v-dialog v-model="showProduct" width="500">
            <div class="modal">
                <ProductProfile
                    v-bind:product="selectedProduct"
                ></ProductProfile>
            </div>
            <v-btn @click="closeModal">Fechar</v-btn>
        </v-dialog>
    </div>
</template>

<script>
import SearchProduct from "../components/SearchProduct.vue";
import ProductProfile from "../components/ProductProfile.vue";
import Invoices from "../components/Invoices.vue";

export default {
    components: {
        SearchProduct,
        ProductProfile,
        Invoices
    },
    name: "StartPage",
    data: function() {
        return {
            nota: "",
            selectedProduct: {},
            showProduct: false,
            selectedInvoice: 1,
            items: [
                
            ],
        };
    },
    methods: {
        openProduct: function(product) {
            if (product) {
                this.selectedProduct = product;
                this.showProduct = true;
            }
            console.log(product);
        },
        closeModal: function() {
            this.showProduct = false;
        },
        startBlankValidation() {
            console.log("Iniciar validação vazia sem nota");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}

.modal {
    background-color: white;
    padding: 10px;
}
</style>
