<template>
    <div>
        <v-combobox
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :filter="customFilter"
            @input="onSelection"
            clearable
            hide-details
            hide-selected
            return-object
            item-text="name"
            item-value="cod"
            label="Busque por um produto"
            dense
        >
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        Digite o código, nome ou apelido...
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <ProductCardHorizontal
                    v-bind:product="item"
                ></ProductCardHorizontal>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import server from "../firebase/firebase.js";
import ProductCardHorizontal from "./ProductCardHorizontal.vue";

export default {
    components: {
        ProductCardHorizontal,
    },
    data: () => ({
        isLoading: false,
        items: [],
        model: null,
        search: null,
    }),
    methods: {
        onSelection(product) {
            this.$emit("selected", product);
        },
        customFilter(item, queryText) {
            const name = item.name.toLowerCase();
            const nickname = item.nickname.toLowerCase();
            const cod = item.cod.toLowerCase();
            const billingCod = item.billingCod.toLowerCase();
            const searchText = queryText.toLowerCase();

            return (
                name.indexOf(searchText) > -1 ||
                nickname.indexOf(searchText) > -1 ||
                cod.indexOf(searchText) > -1 ||
                billingCod.indexOf(searchText) > -1
            );
        },
    },
    watch: {
        async search() {
            // Items have already been loaded
            if (this.items.length > 0 || this.isLoading) {
                return;
            }

            this.isLoading = true;

            // Lazily load input items
            server
                .getProducts()
                .then((products) => {
                    this.items = products;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.avatar {
    height: 60px;
}
</style>
