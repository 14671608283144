import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBV9whMM2_dVUDvoN92DPVP4x-2zTVLBW4",
    authDomain: "ariam-galvanorte.firebaseapp.com",
    projectId: "ariam-galvanorte",
    storageBucket: "ariam-galvanorte.appspot.com",
    databaseURL: "https://ariam-galvanorte-default-rtdb.firebaseio.com/",
    messagingSenderId: "285748519439",
    appId: "1:285748519439:web:0870883ad3ead54ed9797a",
};
firebase.initializeApp(firebaseConfig);

window.firebase = firebase;

const server = {
    saveImage: async function (name, image) {
        let imgUrl = null;

        await firebase
            .storage()
            .ref(`produtos/${name}.jpg`)
            .put(image)
            .then(async function (snapshot) {
                imgUrl = await snapshot.ref.getDownloadURL().then((url) => {
                    return url;
                });
            });
        return imgUrl;
    },
    async getProduct(cod) {
        let db = firebase.firestore();
        let product = null;
        await db
            .collection("produtos")
            .doc(cod)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    product = doc.data();
                } else {
                    // doc.data() will be undefined in this case
                }
            });
        return product;
    },
    async getProducts() {
        let products = [];
        let db = firebase.firestore();
        await db
            .collection("produtos")
            .get()
            .then((snapshot) => {
                snapshot.forEach(async (doc) => {
                    products.push(doc.data());
                });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
        return products;
    },
    async saveProduct(product) {
        let db = firebase.firestore();
        await db
            .collection("produtos")
            .doc(product.cod)
            .set(product, { merge: true });
        return product;
    },
    async getItemsWaitingInvoice() {
        let db = firebase.firestore();
        let products = [];
        await db
            .collection("aguardando_notas")
            .where("status", "==", "waiting")
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var item = {
                        docId: doc.id,
                        cod: doc.data().cod,
                        name: doc.data().name,
                        image: doc.data().image,
                        qty: doc.data().qty,
                        details: doc.data().details,
                        invoiceId: doc.data().invoiceId,
                        originalQty: doc.data().originalQty
                    };
                    products.push(item);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return products;
    },
    async getItemsWaitingParts() {
        let db = firebase.firestore();
        let products = [];
        await db
            .collection("aguardando_fisico")
            .where("status", "==", "waiting")
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var item = {
                        docId: doc.id,
                        cod: doc.data().cod,
                        name: doc.data().name,
                        image: doc.data().image,
                        qty: doc.data().qty,
                        details: doc.data().details,
                        invoiceId: doc.data().invoiceId,
                    };
                    products.push(item);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return products;
    },
    async getInvoices(qty) {
        let db = firebase.firestore();
        let invoices = [];
        await db
            .collection("notas")
            .orderBy("date", "desc")
            .limit(qty)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var invoice = {
                        invoiceId: doc.id,
                        date: doc.data().date,
                        validated: doc.data().validated,
                    };
                    invoices.push(invoice);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return invoices;
    },
    async getProductionSchedule() {
        let db = firebase.database();
        const scheduleRef = await db.ref("scheduler").get();
        return scheduleRef.val();
    },
    async saveSchedule(schedule) {
        let db = firebase.database();
        const scheduleRef = await db.ref("scheduler").set(schedule);
        return scheduleRef;
    },
    async saveDoneBasket(basket) {
        let db = firebase.database();
        const doneBucketRef = await db.ref("done").push().set(basket);
        return doneBucketRef;
    },
    async createDelivery(delivery) {
        let db = firebase.firestore();
        await db
            .collection("entregas")
            .add(delivery);
        return delivery;
    },
    async getDeliveries(qty) {
        let db = firebase.firestore();
        let deliveries = [];
        await db
            .collection("entregas")
            .orderBy("date", "desc")
            .limit(qty)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var delivery = {
                        docId: doc.id,
                        date: doc.data().date,
                        items: doc.data().items,
                    };
                    deliveries.push(delivery);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return deliveries;
    },
    async getInProcessParts() {
        let db = firebase.firestore();
        let products = [];
        await db
            .collection("programacao")
            .orderBy("date", "desc")
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var product = {
                        docId: doc.id,
                        cod: doc.data().cod,
                        name: doc.data().name,
                        date: doc.data().date,
                        image: doc.data().image,
                        qty: doc.data().qty,
                        originalQty: doc.data().originalQty,
                        status: doc.data().status,
                        unloadDate: doc.data().unloadDate,
                        invoiceId: doc.data().invoiceId,
                    };
                    products.push(product);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return products;
    },

};

export default server;
